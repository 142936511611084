.app__specialMenu {
  flex-direction: column;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: var(--color-black);
  content-visibility: auto;
}

.app__specialMenu-title {
  margin-bottom: 2rem;
  text-align: center;
}

.app__specialMenu-menu {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.app__specialMenu-menu_heading_mini {
  font-family: var(--font-base);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: #8c19e6;
}
.app__specialMenu-menu_heading_medium {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 25px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: var(--color-golden);
}
.app__specialMenu-menu_heading {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 45px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: var(--color-golden);
}

.app__specialMenu-menu_heading_titles {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 50px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: var(--color-golden);
}
.flex__start {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 2% 5%;
}

.app__specialMenu-menu_img {
  width: 410px;
  margin: 0 2rem;
}

.app__specialMenu-menu_img img {
  width: 100%;
  height: auto;
}

.app__specialMenu_menu_items {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
}

.pink-text {
  color: #8c19e6;
}

.menu-table {
  width: 100%;
}

@media screen and (min-width: 2000px) {
  .app__specialMenu-menu_img {
    width: 650px;
  }

  .app__specialMenu-menu_img img {
    height: 920px;
  }
}

@media screen and (max-width: 1150px) {
  .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .app__specialMenu-menu_img {
    margin: 3rem 0;
  }
}

@media screen and (max-width: 650px) {
  .app__specialMenu-menu_img {
    width: 100%;
  }

  .app__specialMenu-menu_heading {
    font-size: 40px;
    line-height: 48.5px;
    font-size: 12px;
  }
}
