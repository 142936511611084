.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal__content {
    background-color: #fff;
    max-width: 700px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 90vh;
  }
  
  .modal__title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal__text {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .modal__close {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #F44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .modal__close:hover {
    background-color: #fff;
    color: #F44336;
    border: 2px solid #F44336;
  }
  