.app__menuitem {
  width: 100%;
  margin: 1rem 0;

  display: flex;
  flex-direction: column;
}
.app__menuitem-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__menuitem-sub {
  width: 100%;
  margin-top: 0.2rem;
}
.app__menuitem-alergenics {
  width: 100%;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.app__menuitem-sub img {
  width: 80%;
}

.app__menuitem-alergenics img {
  object-fit: contain;
  height: 20px;
}

.app__menuitem-name {
  flex: 1;
}

.app__menuitem-dash {
  width: 90px;
  height: 2px;
  background: #a600ff;
  margin: 0 1rem;
  margin-top: 10px;
}

.app__menuitem-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__menuitem-price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}